<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div class="grid grid-cols-12">
        <div class="box col-span-7">
          <div class="items-center flex justify-between p-2">
            <div>
              <span class="flex items-center" >
                <span @click="$router.go(-1)" class="cursor-pointer pt-1"><i class="fas fa-arrow-left h-5 w-5"></i></span>
                <div class="heading-2 text-text1 pl-3">
                  {{ componentList.componentName }}
                </div>
              </span>
            </div>
            <div>
              <!-- <span class="px-1 pr-3 cursor-pointer" @click="editCompDetail()">
                <i class="fas fa-edit text-primary h-6 w-6" ></i>
              </span> -->
              <button class="list_top_card_btn bg-primary font-bold cta-text text-white py-2 px-2 rounded-3xl" @click="editCompDetail()">Edit</button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;" class="table_containder " >
              <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
                <div class="name_col flex items-center">
                  <span class="cursor-pointer">{{titleObj[0].title}} </span>
                  <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
                  <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
                </div>
                <div class="cell_col flex items-center">
                  <span class="cursor-pointer">{{titleObj[1].title}} </span>
                  <img v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
                  <img v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
                </div>
              </div>
              <div v-for="(data, index) in componentList.componentFieldList" :key="index" class="min-w-min group   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-3 items-center cursor-pointer hover:bg-gray-50">
                <div class="name_col">
                  <span class="heading-4  text-text2 font-medium">{{data.filedName}}</span>
                </div>
                <div class="cell_col">
                  <!-- <span class="heading-4  text-text2 font-medium">{{data.inputFieldName}} <span class="heading-5 text-gray4">({{data.fieldDataType}})</span></span> -->
                  <span class="heading-4  text-text2 font-medium">{{data.inputFieldName}}</span>
                </div>
              </div>
          </div>
          <div class="layout my-2" v-if="componentList.length === 0">
              <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div v-if="addComponentDialog" class="popup_overlay">
        <div class="bg-white rounded-sm p-4 custom_dialog" style="width:550px">
          <addComponent :componentMasterId="componentMasterId"></addComponent>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import HRMAPI from '@/View/CMS/api/HRM.js'
import addComponent from '@/View/CMS/project/pages/blog/component/addComponent.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    addComponent,
  },
  data () {
    return {
      componentMasterId: 0,
      showDetailPopup: false,
      userDetailValue: [],
      mobileView: false,
      addComponentDialog: false,
      istrue: true,
      compObject: [],
      titleObj: [
        {title: 'Name', icon: '', sortName: '', sortVal: ''},
        {title: 'Type', icon: '', sortName: '', sortVal: ''},
    ],
      cancelFilter: {
        selectedBranch: [],
        selectedDepartment: []
      },
      page: 1,
      componentList: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.componentMasterId = parseInt(this.$route.params.componentId)
    this.resizeWindowHandler();
    document.title = 'Blog Component Detail'
    this.getCompDetailList()
    this.$root.$on("closeComponentDialog", () => {
      this.addComponentDialog = false
      this.getCompDetailList()
    })
  },
  watch: {
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    }
  },
  methods: {
    editCompDetail () {
      console.log('parseInt(this.$route.params.componentId)', parseInt(this.$route.params.componentId))
      this.componentMasterId = parseInt(this.$route.params.componentId)
      this.addComponentDialog = true
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    getCompDetailList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.BlogComponentDetail(
        parseInt(this.$route.params.componentId),
        response => {
          this.componentList = response.Data === null ? [] : response.Data
          console.log("response 123", this.componentList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    this.$root.$off('closeComponentDialog')
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 810px !important;
  width: 810px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px;
}
.list_top_card_btn {
  width: 90px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
</style>
